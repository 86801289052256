<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row v-auth-acl="'gh-proy-mis_proy-read'">
      <v-col cols="12" sm="12">
        <BaseCard>
          <MatrizProyectoGeneral @open="open" @close="close"></MatrizProyectoGeneral>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MatrizProyecto',
  components: {
    MatrizProyectoGeneral: () => import('../components/MatrizProyectoGeneral')
  },
  data: () => ({
    page: {
      title: 'PROYECTOS'
    },
    breadcrumbs: [
      {
        text: 'Proyectos',
        disabled: true
      },
      {
        text: 'Mis Proyectos',
        disabled: false
      }
    ]
  }),
  methods: {
    open() {},
    close() {}
  }
};
</script>

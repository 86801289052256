<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row v-auth-acl="'gh-proy-mat_seg-read'">
      <v-col cols="12" sm="12">
        <BaseCard>
          <MatrizProyectoLista @open="open" @close="close"></MatrizProyectoLista>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Matriz',
  components: {
    MatrizProyectoLista: () => import('../components/MatrizProyectoLista')
  },
  data: () => ({
    page: {
      title: 'PROYECTO'
    },
    breadcrumbs: [
      {
        text: 'Proyecto',
        disabled: true
      },
      {
        text: 'Matriz de Seguimiento',
        disabled: false
      }
    ]
  }),
  methods: {
    open() {},
    close() {}
  }
};
</script>
